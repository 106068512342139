import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CompMenuMiniBanner() {
  const [bannerMini, setBanner] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner-mini`);
        if (!response.ok) {
          throw new Error('Error al obtener los banners-mini');
        }
        const bannerMini = await response.json();

        // Obtener el índice del último banner mostrado desde localStorage
        let currentIndex = parseInt(localStorage.getItem('bannerMiniIndex'), 10) || 0;

        // Incrementar el índice para mostrar el siguiente banner
        currentIndex = (currentIndex + 1) % bannerMini.length;

        // Guardar el nuevo índice en localStorage
        localStorage.setItem('bannerMiniIndex', currentIndex);

        // Establecer el banner actual
        let currentBanner = bannerMini[currentIndex];

        // Verificar si el enlace ya existe y es válido
        const enlaceExiste = currentBanner.enlace_banner && currentBanner.enlace_banner.trim() !== "";

        // Si el enlace está vacío o nulo, asignar una ruta alternativa
        if (!enlaceExiste) {
          currentBanner.enlace_banner = `/comercio/${currentBanner._id}`; // Ruta alternativa
        }

        setBanner(currentBanner);
      } catch (error) {
        console.error('Error al obtener el banner:', error);
      }
    };

    fetchBanners();
  }, []); // El array vacío hace que useEffect se ejecute solo una vez al montar el componente

  if (!bannerMini) {
    return null; // No renderiza nada si no hay banner
  }


  return (
    <div className="minibanner">
      <div className="minibanner-background" style={{ backgroundImage: `url(/comercios/comer_${bannerMini._id}_mb.jpg)` }}></div>
      <Link to={bannerMini.enlace_banner} style={{ textDecoration: 'none' }} target={bannerMini.enlace_banner.startsWith('http') ? "_blank" : "_self"} rel={bannerMini.enlace_banner.startsWith('http') ? "noopener noreferrer" : undefined}>
        <div className="minibanner-principal">
          <img src={`/comercios/comer_${bannerMini._id}_mb.jpg`} alt="Banner" />
        </div>
      </Link>
    </div>

  );
}

export default CompMenuMiniBanner;
