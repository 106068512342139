import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import FichaPrepare from '../components/vistas/FichaPrepare.js';
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFComparsa() {
  
  const { idComparsa } = useParams(); // Recoge el parámetro `id` de la URL

  return (
    <div>
      <Minibanner />
      <Relleno />
      <FichaPrepare type="comparsa" id={idComparsa} />
    </div>
  );
}

export default PageFComparsa;