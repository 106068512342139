import React from 'react';
import MinibannerPropio from '../components/menu/CompMenuMinibannerPropio.js';
import FichaPrepare from '../components/vistas/FichaPrepare.js';
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFComercio() {
  
  const { idComercio } = useParams(); // Recoge el parámetro `id` de la URL

  return (
    <div>
      <MinibannerPropio id={idComercio}/>
      <Relleno />
      <FichaPrepare type="comercio" id={idComercio} />
    </div>
  );
}

export default PageFComercio;