import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Popup } from 'react-leaflet';
import L from 'leaflet';

// Ícono personalizado para los marcadores (opcional, puedes usar el ícono por defecto)
const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41],
});

// Componente del mapa que acepta `punto` y `recorrido` como props
const MapComponent = ({ punto, recorrido }) => {
  const [markers, setMarkers] = useState([]);
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const mapRef = useRef();  // Referencia al mapa

  // Procesar los valores de `punto` para extraer latitudes, longitudes y títulos
  useEffect(() => {
    if (punto) {
      const markerData = punto.split(';').map((segment) => {
        const [lat, lng, title] = segment.split(',').map((value, index) =>
          index < 2 ? parseFloat(value.trim()) : value.trim()
        );
        return { lat, lng, title };
      });
      setMarkers(markerData);  // Actualizar los marcadores con los datos procesados
    }
  }, [punto]);

  // Procesar el recorrido para extraer las coordenadas
  useEffect(() => {
    if (recorrido) {
      const routeData = recorrido.split(';').map((segment) => {
        const [lat, lng] = segment.split(',').map((coord) => parseFloat(coord.trim()));
        return { lat, lng };
      });
      setRouteCoordinates(routeData);  // Actualizar las coordenadas de la polilínea
    }
  }, [recorrido]);

  // Centrar el mapa en el conjunto de puntos (marcadores + ruta)
  useEffect(() => {
    if (mapRef.current && (markers.length > 0 || routeCoordinates.length > 0)) {
      const allCoordinates = [
        ...markers.map(marker => [marker.lat, marker.lng]),  // Coordenadas de los marcadores
        ...routeCoordinates.map(coord => [coord.lat, coord.lng]),  // Coordenadas de la ruta
      ];

      // Crear límites que ajusten todos los puntos (marcadores + ruta)
      const bounds = new L.LatLngBounds(allCoordinates);
      mapRef.current.fitBounds(bounds);  // Ajustar los límites del mapa para mostrar todos los puntos
    }
  }, [markers, routeCoordinates]);

  return (
    <MapContainer
      style={{ height: '300px', width: '100%' }}  // Estilo del contenedor del mapa
      ref={mapRef}  // Referencia al mapa
      zoom={15}  // Nivel de zoom inicial (será ajustado por los límites)
    >
      {/* Capa base del mapa: OpenStreetMap */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />


      {/* Renderizar los marcadores */}
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={[marker.lat, marker.lng]}
          icon={customIcon}
        >
          {/* Popup para mostrar el título del marcador */}
          <Popup>{marker.title}</Popup>
        </Marker>
      ))}

      {/* Renderizar la polilínea para mostrar el recorrido */}
      {routeCoordinates.length > 1 && (
        <Polyline
          positions={routeCoordinates.map(({ lat, lng }) => [lat, lng])}  // Crear la ruta con las coordenadas
          color="green"  // Color de la polilínea
          weight={8}  // Grosor de la línea
          opacity={0.7}  // Opacidad de la línea
/*           dashArray="10, 20"  // Líneas punteadas o guiones (10px línea, 20px espacio)
          dashOffset="0" */
        />
      )}
    </MapContainer>
  );
};

export default MapComponent;
