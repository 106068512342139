import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import ListaActos from '../components/vistas/ListaActos.js';

function PageListaActos() {
  return (
    <div>
      <Minibanner />
      <ListaActos />
    </div>
  );
}

export default PageListaActos;
