import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom

function Grid10M({ items, ruta, src, titulo }) {
  const navigate = useNavigate();

  return (
    <div className="home2">
      <div className="grid-container">
        {items.map((item, index) => {
          if (index === 0) {
            return (
              <React.Fragment key={index}>
                <div
                  onClick={() => navigate(`${titulo === "Comparsas" ? `${ruta}${item.id}/info` : `${ruta}${item.id}`}`)}
                  className="grid-item grid-item-large"
                >
                  <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image" />
                  <b>{item.nombre}</b>
                </div>
                <div className="grid-title"><b>{titulo}</b></div>
              </React.Fragment>
            );
          }
          return (
            <div
              key={index}
              onClick={() => navigate(`${ruta}${item.id}`/* , { replace: true } */)}
              className="grid-item"
            >
              <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image" />
              <p>{item.nombre}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Grid10M;
