import React from 'react';
import { useNavigate } from 'react-router-dom';

function Grid00M({ items, ruta, src, titulo }) {
  const navigate = useNavigate();

  if (items.length === 0) {
    return <div>No hay items para mostrar.</div>;
  }

  const firstItem = items[0];

  return (
    <div className="home2">
      <div className="grid-container">
        <div className="grid-title">
          <b>{titulo}{firstItem.nombrePadre}</b>
        </div>
        {items.map((item, index) => (
          <div
            key={index}
            className="grid-item"
            onClick={() => navigate(`${ruta}${item.id}`/* , { replace: true } */)}
          >
            <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image" />
            <p>{item.nombre}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Grid00M;

