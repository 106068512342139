export const parseTextToLinks = (text) => {
    const items = text.split(';').map((item) => item.trim());
    const linkArray = [];
  
    items.forEach((item) => {
      const [type, value] = item.split(',').map((str) => str.trim());
      if (type && value) {
        linkArray.push({ type, value });
      }
    });
  
    return linkArray;
  };