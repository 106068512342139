import React from 'react';
import { useLocation } from 'react-router-dom';
import ListaMap from '../components/vistas/ListaMap';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Relleno from '../components/menu/CompMenuMBT';



function PageMap() {
    const location = useLocation();
    const { punto, recorrido } = location.state || {}; // Extraer los valores de state


    return (
        <div>
            <Minibanner />
            <Relleno />
            {punto || recorrido ? (
            <ListaMap punto={punto} recorrido={recorrido} />
            ) : (
            <p>No hay datos de mapa disponibles.</p>
            )}
        </div>
    );
};

export default PageMap;

