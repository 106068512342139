import React, { useState, useEffect, useRef } from 'react';

function SpinnerComparsas({ onSelectionChange, selectedId, type }) {
    const [options, setOptions] = useState([]);
    const [internalSelectedId, setInternalSelectedId] = useState(selectedId); // Inicializar con el prop

    // Actualiza el estado interno cuando `selectedId` del componente padre cambie
    useEffect(() => {
        setInternalSelectedId(selectedId);
    }, [selectedId]);

    useEffect(() => {
        // Fetch data from the API
        fetch(`${process.env.REACT_APP_API_URL}/comparsas`)
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    // Maneja la selección de la comparsa
    const handleSelect = (id) => {
        setInternalSelectedId(id);
        setIsOpen(false); // Cierra el menú después de la selección
        if (onSelectionChange) {
            onSelectionChange(id); // Sincroniza la selección con el padre
        }
    };
    
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null); // Referencia para el dropdown


    // Toggle para abrir/cerrar el dropdown
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectedOption = options.find(option => option.id === Number(internalSelectedId)) || options[0]; // Valor por defecto

    // Cierra el menú si se hace clic fuera del área del dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false); // Cierra el menú
        }
    };

    // Escucha los clics fuera del área del menú para cerrarlo
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div>
            <div className="custom-select-wrapper" ref={dropdownRef}>
                <div className="custom-select-selected" onClick={toggleDropdown}>
                    {selectedOption && (
                        <>
                            <img src={`/comparsas/c_${internalSelectedId}.png`} className="custom-select-image" alt="Selected" />
                            <div className="custom-select-text-pack">
                                <span className="custom-select-text">
                                    {selectedOption.id === 0 && (type === 'actos' || type === 'ordenes') ? "Actos Generales" : selectedOption.nombre}
                                </span>
                            </div>
                        </>
                    )}
                    <div className={`custom-select-arrow ${isOpen ? 'open' : ''}`}></div>
                </div>
                {isOpen && (
                    <div className="custom-select-options">
                        {options.map(option => (
                            <div 
                                key={option.id} 
                                className="custom-select-option" 
                                onClick={() => handleSelect(option.id)}
                            >
                                <img 
                                    src={`/comparsas/c_${option.id}.png`} 
                                    className="custom-select-option-image" 
                                    alt={option.nombre} 
                                />
                                <span className="custom-select-option-text">
                                    {option.id === 0 && (type === 'actos' || type === 'ordenes') ? "(Actos Generales)" : option.nombre}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SpinnerComparsas;