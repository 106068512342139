// CompCookiesModal.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { CookiesContext } from '../../context/CookiesContext'; // Importamos el contexto
import ListaAvisoLegal from '../vistas/ListaAvisoLegal';

function CompCookiesModal() {
  const {
    politicas,
    setPoliticas,
    ubicacion,
    setUbicacion,
    almacenamiento,
    setAlmacenamiento,
    personalizacion,
    setPersonalizacion,
    setCookiesAceptadas,
  } = useContext(CookiesContext);

  // Estado para controlar la visibilidad del contenido de políticas
  const [showPolicies, setShowPolicies] = useState(false);
  const policiesRef = useRef(null); // Referencia para el div de políticas

  const togglePolicies = () => {
    setShowPolicies(!showPolicies);
  };
  

  // Cerrar el div al hacer clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (policiesRef.current && !policiesRef.current.contains(event.target)) {
        setShowPolicies(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [policiesRef]);



  // Manejar cambios en los checkbox de almacenamiento, politicas, ubicación y personalización
  const handleUbicacionChange = (e) => {
    setUbicacion(e.target.checked);
    console.log('Ubicación actualizada:', e.target.checked);
  };

  const handlePoliticasChange = (e) => {
    setPoliticas(e.target.checked);
    console.log('politicas actualizada:', e.target.checked);
  };

  const handleAlmacenamientoChange = (e) => {
    setAlmacenamiento(e.target.checked);
    console.log('Almacenamiento actualizado:', e.target.checked);
  };

  const handlePersonalizacionChange = (e) => {
    setPersonalizacion(e.target.checked);
    console.log('Personalización actualizada:', e.target.checked);
  };


  const [errorMessage, setErrorMessage] = useState('');

  
  // Función para rechazar todo (pone almacenamiento, politicas, ubicación y personalización en false)
  const rechazarTodo = () => {
    setUbicacion(false);
    setPoliticas(false);
    setAlmacenamiento(false);
    setPersonalizacion(false);
    setCookiesAceptadas(false); // Mantener cookiesAceptadas en false
    // Mostrar alerta
    setErrorMessage('Para seguir tienen que estar aceptadas todas las opciones');
    console.log('Preferencias rechazadas: almacenamiento, politicas, ubicación y personalización en false');
  };

  // Función para guardar las preferencias actuales sin cerrar el modal
  const guardarPreferencias = () => {
    if (almacenamiento && personalizacion && ubicacion && politicas) {
      // Mostrar alerta si las opciones no están seleccionadas
      setCookiesAceptadas(true);  // Solo cierra el modal si todas están aceptadas
      console.log('Preferencias guardadas: Almacenamiento:', almacenamiento, 'Personalización:', personalizacion, 'politicas:', politicas, 'Ubicación:', ubicacion);

    } else {
      setErrorMessage('Para seguir tienen que estar aceptadas todas las opciones');
      console.log('Error: Ambos checkboxes no están marcados');
    }
  };

  // Función para aceptar todo (pone almacenamiento, ubicación y personalización en true y cierra el modal)
  const aceptarTodo = () => {
    setPoliticas(true);
    setUbicacion(true);
    setAlmacenamiento(true);
    setPersonalizacion(true);
    setCookiesAceptadas(true); // Cierra el modal al aceptar todo
    console.log('Todas las cookies aceptadas: almacenamiento, ubicación, ubicación y personalización en true');
  };







  return (
<div className="modal-overlay">
  <div className="modal-content" style={{ backgroundColor: 'rgba(250, 250, 250, 0.95)' }}>
    <div className="modal-content-int">

      <div className="grid-title">
        <b>Aviso de Cookies</b>
      </div>
      <div className="text-cook">
        <p>
          <br/>
          <b>Se guardan en su dispositivo las cookies mínimas necesarias</b> para la gestión correcta de la aplicación, no se recopila información adicional ni se rastrea su actividad.<br/><br/>
          <b style={{ color: 'rgb(12, 110, 12)', fontSize: '14px' }}>Deben ser aceptadas para poder continuar.</b>
        </p>

          <label>
            <input
              type="checkbox"
              checked={almacenamiento}
              onChange={handleAlmacenamientoChange}/>
            <b>Almacenamiento</b>
          </label>
          <br />

          <label>
            <input
              type="checkbox"
              checked={personalizacion}
              onChange={handlePersonalizacionChange}/>
            <b>Personalización</b>
          </label>
          <br />

{/*           <label>
            <input
              type="checkbox"
              checked={ubicacion}
              onChange={handleUbicacionChange}/>
            <b>Ubicación</b>
          </label> 
          <br />*/}

          <label>
              <input 
              type="checkbox" 
              checked={politicas} 
              onChange={handlePoliticasChange} />
              <b>He leído y acepto las <span style={{ cursor: 'pointer', color: 'blue' }} onClick={togglePolicies}>políticas de privacidad</span>.</b>
            </label>

           {/* Contenedor con scroll para mostrar el contenido de las políticas */}
           {showPolicies && (
               <div ref={policiesRef} style={{ maxHeight: '100px', overflowY: 'auto', border: '1px solid #ccc', marginTop: '10px', padding: '10px' }}>
                {/* Aquí puedes colocar el contenido o componente que muestra las políticas */}
                <ListaAvisoLegal />
              </div>
            )}

        <p>
          Esta aplicación <b>NO almacena sus datos personales</b>.<br/>
        </p>

        {/* Muestra un mensaje de error si es necesario */}
        {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}

        <div className="boton-pref-container">
          <button className="boton-pref" onClick={rechazarTodo}>Rechazar todo</button>
          <button className="boton-pref" onClick={guardarPreferencias}>Guardar preferencias</button>
          <button className="boton-pref-ver" onClick={aceptarTodo}><b>Aceptar todo</b></button>
        </div>

        <br/><br/>
      </div>

    </div>
  </div>
</div>
  );
}

export default CompCookiesModal;
// {<div className="modal-overlay" onClick={handleClose}>
// <div className="modal-content"
//   ref={modalRef} /* onClick={handleInsideClick} */>

//   <div className="modal-content-int">
//     <button className="modal-close" onClick={handleClose}>
//       &times;
//     </button>

//     <div className="grid-title">
//         <b>Mi Perfil</b>
//     </div>

//     {/* Comparsa y Fila */}
//     <div className="perfil-section">
//     {comparsa === '0' ? (
//         <>
//           {/* Mostrar Actos Generales si la comparsa es '0' */}
//           <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
//             <strong>Mi Comparsa</strong> Actos Generales
//             <div className="perfil-image-container">
//              <img src={`/comparsas/c_${comparsa}.png`} alt="Mi Comparsa" className="perfil-image" />
//             </div>
//             <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
//           </Link>
//           <div className="perfil-item">
//             <strong>Mi Fila</strong> Sin Comparsa seleccionada
//           </div>
//         </>
//       ) : (
//         <>
//           {/* Mostrar información normal si hay una comparsa seleccionada */}
//           <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
//             <span>Mi Comparsa</span>
//             <div className="perfil-image-container">
//               <img src={`/comparsas/c_${comparsa}.png`} alt="Mi Comparsa" className="perfil-image" />
//             </div>
//             <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
//           </Link>
//           <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
//             <span>Mi Fila</span>
//             <div className="nombre-fila"><b> {filaNombre || 'No seleccionada'} </b></div>
//             <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
//           </Link>
//         </>
//       )}

//     </div>

//     {/* Actos Obligatorios */}
//     {filaNombre && (
//       <div className="perfil-item">
//         <strong>Actos asignados a mi Fila:</strong>
//         {actosObligatorios.length > 0 ? (
//           <ul>
//             {actosObligatorios.map((acto) => (
//               <li key={acto.id}>
//                 <strong>{acto.dia}</strong> - {acto.nombre} a las {acto.hora}
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p style={{ color: 'red' }}>Próximamente.<br/>En desarrollo.<br/>No hay actos obligatorios disponibles.</p>
//         )}
//       </div>
//     )}
//   </div>


// </div>
// </div>}