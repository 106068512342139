import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate
import logo from '../../assets/logo1024t.png';
import CompUserProfileModal from './CompUserProfileModal'; 
import CompCookiesModal from './CompCookiesModal';
import { ComparsaContext } from '../../context/ComparsaContext';
import { CookiesContext } from '../../context/CookiesContext';

function CompMenuHeader() {
  const { selectedComparsaId } = useContext(ComparsaContext);
  const { cookiesAceptadas } = useContext(CookiesContext);
  const navigate = useNavigate(); // Inicializamos useNavigate

  // Estado para controlar la visibilidad del modal de perfil
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Función para abrir/cerrar el modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Función para redirigir al home y borrar el historial
  const handleLogoClick = () => {
    navigate('/', { replace: true }); // Redirige y reemplaza el historial
  };

  return (
    <div className="nav">
      <div className="nav-content">

        <div className="nav-item left">
          {/* Cambié el Link a un div con onClick para redirigir y borrar el historial */}
          <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <img src={logo} alt="Vuelta al menú" />
          </div>
        </div>

        <div className="nav-item">
          {/* Botón para abrir/cerrar el modal */}
          <button 
            className="profile-button"
            onClick={toggleModal}
            style={{ cursor: 'pointer' }}
          >
            <span className={`up-arrow ${isModalOpen ? 'open' : ''}`}></span>
            <img src={`/comparsas/c_${selectedComparsaId}.png`} alt="Perfil" />
          </button>
        </div>

        {/* Renderizar el modal de perfil si isModalOpen es true */}
        {isModalOpen && (
          <CompUserProfileModal onClose={toggleModal} />
        )}

        {/* Mostrar el modal de cookies si no se han aceptado */}
        {!cookiesAceptadas && <CompCookiesModal />}
      </div>
    </div>
  );
}

export default CompMenuHeader;
