import React, { createContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
  });

  const login = (user) => {
    setState({ ...state, user });
  };

  return (
    <AppContext.Provider value={{ state, login }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };