import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import FichaPrepare from '../components/vistas/FichaPrepare.js';
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFOrden() {
  
  const { idOrden } = useParams(); // Recoge el parámetro `id` de la URL

  return (
    <div>
      <Minibanner />
      <Relleno />
      {<FichaPrepare type="orden" id={idOrden} />}
    </div>
  );
}

export default PageFOrden;
