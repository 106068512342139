import React, { useEffect, useState, useContext } from 'react';
import logo from '../../assets/logo1024t.png';
import CompCookiesModal from '../menu/CompCookiesModal';
import { CookiesContext } from '../../context/CookiesContext';

function ListaAvisoLegal() {

    return (
        <div>
            <div className="text-vent justify">
              <p >
              <b>Aviso legal, Política de Privacidad y Política de Cookies</b>
                <br/><br/>
El presente aviso legal recoge las condiciones generales que rigen el acceso y el uso de la aplicación Moros y Cristianos Petrer.<br/><br/>

El acceso y uso de la aplicación implica la expresa y plena aceptación de estas condiciones generales en la versión publicada en el momento en que el usuario acceda al mismo, sin perjuicio de las condiciones particulares que pudieran aplicarse en algunos de los servicios concretos de la aplicación.<br/><br/>

El usuario acepta que el acceso a la aplicación y de los contenidos incluidos en el mismo tiene lugar libre y conscientemente, bajo su exclusiva responsabilidad.<br/><br/>
Los usuarios acceden a la aplicación de forma libre y gratuita, se reserva el derecho de limitar su acceso para determinados contenidos o servicios, exigiendo el registro de sus datos previamente al acceso.<br/><br/>

Se reserva el derecho a modificar en cualquier momento la presentación, configuración y localización de la aplicación, así como los contenidos, servicios y las condiciones requeridas para utilizar los mismos cuando lo considere oportuno o con la finalidad de adecuarse a los cambios legislativos y tecnológicos futuros. Estas modificaciones serán válidas desde su publicación en la aplicación.<br/><br/>

Se reserva el derecho a interrumpir el acceso a la  presente  aplicación en cualquier momento y sin previo aviso, ya sea por motivos técnicos, de seguridad, de control, de mantenimiento, por fallos de suministro eléctrico, o por cualquier otra causa. Dicha interrupción podrá tener carácter temporal o definitivo, en cuyo caso se comunicará tal circunstancia a los usuarios, quienes podrán sufrir la pérdida, en su caso, de la información almacenada en los distintos servicios, sin dar lugar a indemnización alguna.<br/><br/><br/><br/>

<b>AVISO DE COPYRIGHT</b><br/><br/>
Todos los derechos de propiedad industrial e intelectual sobre esta aplicación están legalmente reservados, por lo que el acceso al mismo o su utilización, por parte del usuario, no debe considerarse, en forma alguna, como el otorgamiento de ninguna licencia de uso o derecho sobre cualquier activo.<br/><br/>
Los usuarios de la aplicación únicamente podrán realizar un uso privado, personal o profesional de los contenidos de éste. Está absolutamente prohibido, el uso de la aplicación o de alguno de sus elementos con fines comerciales o ilícitos.<br/><br/>
Queda prohibida la reproducción de los contenidos de la aplicación, salvo que esté permitido legalmente y se cite la procedencia o, en su defecto, se autorice por escrito lo contrario.<br/><br/><br/><br/>

<b>CLÁUSULA DE EXENCIÓN DE RESPONSABILIDAD</b><br/><br/>
Los contenidos incorporados a la aplicación han sido elaborados en todo momento de buena fe con el propósito de dar información a los usuarios, no se asume ningún tipo de responsabilidad respecto a la integridad o exactitud de la citada información ni se garantiza la exactitud ni la puesta al día de la información que se pueda obtener del mismo, la cual podrá ser modificada sin previo aviso. Tampoco se responsabiliza de los eventuales errores tipográficos, formales o numéricos que pueda contener la aplicación.<br/><br/>
Los usuarios se responsabilizan del mal uso que puedan hacer de la aplicación.<br/><br/><br/><br/>

<b>CONFIDENCIALIDAD Y SECRETO PROFESIONAL.</b><br/><br/>
Las comunicaciones privadas que pudieran darse entre el personal que desarrolle la aplicación y los usuarios o visitantes serán consideradas como confidenciales. El acceso a esta información está restringido mediante herramientas tecnológicas y mediante estrictos controles internos.<br/><br/>

La presente aplicación puede contener enlaces o links con otros sitios. El titular de la cuenta de desarrollador no dispone de control alguno ni ostenta responsabilidad alguna sobre las políticas o medidas de protección de datos de otros sitios web.<br/><br/><br/><br/>

<b>POLÍTICA DE PRIVACIDAD Y COOKIES</b><br/><br/>
Todos los datos personales que estén bajo nuestra responsabilidad serán tratados conforme a la Ley 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, y se implementarán las medidas de seguridad y confidencialidad correspondientes.<br/><br/>
El uso de la aplicación Moros y Cristianos Petrer no requiere que el usuario proporcione información personal identificable. Si se llegaran a suministrar datos de carácter personal, estos serán tratados conforme a los derechos recogidos en la normativa vigente.<br/><br/>
La aplicación utiliza únicamente cookies mínimas necesarias para almacenar las preferencias del usuario y asegurar el correcto funcionamiento de la misma, sin recopilar información adicional ni rastrear su actividad.<br/><br/><br/>


<b>¿Cómo hemos obtenido sus datos?</b><br/><br/>
Los datos personales que tratamos en la aplicación Moros y Cristianos Petrer han sido obtenidos por la descarga de la misma.<br/><br/><br/>

<b>¿Con qué finalidad tratamos sus datos personales?</b><br/><br/>
En la aplicación Moros y Cristianos Petrer usamos sus datos personales únicamente para la gestión correcta de la aplicación.<br/><br/><br/>

<b>¿Cuál es la legitimación para el tratamiento de sus datos?</b><br/><br/>
La base legal para el tratamiento de sus datos es la gestión de la aplicación, con el envío de publicaciones comerciales incluido.<br/><br/><br/>

<b>¿Por cuánto tiempo conservaremos sus datos?</b><br/><br/>
Cuando hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recogidos, serán cancelados conforme establece la normativa de protección de datos de carácter personal.<br/><br/><br/>

<b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b><br/><br/>
Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan, o no.  Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. Mediante correo electrónico con fotocopia de DNI a aplicacionmyc@gmail.com<br/><br/>

En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.<br/><br/>
En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. El encargado del desarrollo dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.<br/><br/>

Puede usted en cualquier momento oponerse al envío del boletín informativo, tanto por medios postales como electrónicamente a la dirección de correo electrónico anteriormente indicada.<br/><br/>




              </p>
            </div>
        </div>
    );
}

export default ListaAvisoLegal;
