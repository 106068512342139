import React, { useEffect, useState, useContext } from 'react';
import SpinnerComparsas from '../spinners/SpinnerComparsas';
import { ComparsaContext } from '../../context/ComparsaContext'; // Importa el contexto
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom

function ListaAjustes() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); // Forzar el desplazamiento al inicio de la página
    }, []);

    // Usa el contexto para manejar el estado de la comparsa seleccionada
    const { selectedComparsaId, setSelectedComparsaId } = useContext(ComparsaContext);

    const [filas, setFilas] = useState([]); // Lista de filas disponibles
    // const [selectedComparsaId, setSelectedComparsaId] = useState(localStorage.getItem('selectedComparsa') || ''); // Comparsa seleccionada
    const [selectedFilaId, setSelectedFilaId] = useState(localStorage.getItem('selectedFilaId') || ''); // ID de la fila seleccionada
    const [selectedFilaNombre, setSelectedFilaNombre] = useState(localStorage.getItem('selectedFilaNombre') || ''); // Nombre de la fila seleccionada

/*     if (!selectedComparsaId) {
        localStorage.setItem('selectedComparsa', 0);
    } */

    // Estado para la selección persistente (de localStorage)
    const handleSelectionChange = (id) => {
        // Sincroniza la selección de comparsa con el localStorage y el estado
        // localStorage.setItem('selectedComparsa', id);
        setSelectedComparsaId(id);

        // Restablece los valores de Fila al cambiar de comparsa
        localStorage.removeItem('selectedFilaId');
        localStorage.removeItem('selectedFilaNombre');
        setSelectedFilaId('');
        setSelectedFilaNombre('');

    };

    // Obtener las filas disponibles según la comparsa seleccionada
    useEffect(() => {
        if (selectedComparsaId) {
            fetch(`${process.env.REACT_APP_API_URL}/filas/${selectedComparsaId}`)
                .then(response => response.json())
                .then(data => {
                    setFilas(data); // Guarda las filas en el estado
                })
                .catch(error => console.error('Error fetching filas:', error));
        }
    }, [selectedComparsaId]); // Este efecto se ejecuta cuando cambia la comparsa seleccionada

    // Cambia handleFilaChange para almacenar tanto el id como el nombre de la fila
    const handleFilaChange = (e) => {
        const selectedFilaId = e.target.value; // Almacena el id de la fila
        const selectedFilaNombre = e.target.options[e.target.selectedIndex].text; // Almacena el nombre de la fila

        setSelectedFilaId(selectedFilaId); // Actualiza el estado del id de la fila
        setSelectedFilaNombre(selectedFilaNombre); // Actualiza el estado del nombre de la fila

        localStorage.setItem('selectedFilaId', selectedFilaId); // Guarda el id en localStorage
        localStorage.setItem('selectedFilaNombre', selectedFilaNombre); // Guarda el nombre en localStorage
    };



    return (
        <div>
            <div className="home2">
                <div className="grid-container-t">
                    <div className="grid-title">
                        <b>Ajustes</b>
                    </div>      
                </div>
                <div className="home-vent">
                    <div className="title-vent">
                        <b>Seleccione su Comparsa</b>
                    </div>
                    <div className="text-vent">
                        <p>Si no pertenece a ninguna,</p>
                        <p>seleccione "Actos Generales"</p>
                    </div>
                    {/* Selector de Comparsa con Spinner */}
                    {<SpinnerComparsas onSelectionChange={handleSelectionChange} selectedId={selectedComparsaId} />}

                    {/* Solo muestra la selección de fila si la comparsa seleccionada no es "0" */}
                    
                    {selectedComparsaId && selectedComparsaId !== '0' ? (
                        <>
                            <div className="title-vent">
                                <b>Seleccione su Fila</b>
                            </div>
                            <div className="text-vent">
                                <p>Solamente si pertenece a alguna.</p>
                                <p>Destacarán los actos</p>
                                <p>asignados a su fila.</p>
                            </div>
                            <div>
                                <select
                                    id="fila"
                                    value={selectedFilaId}
                                    onChange={handleFilaChange}
                                    className="custom-select-selected"
                                    style={{ padding: "15px" }}
                                >
                                    {/* Almacenamos una opción con valor vacío */}
                                    <option value="" disabled>-- Seleccione su Fila --</option>
                                    <option value="-2">-- Ninguna --</option> {/* La opción "Ninguna" */}
                                    {filas.map(fila => (
                                    <option key={fila.id} value={fila.id}>
                                        {fila.nombre}
                                    </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    ) : null}


                    <div className="text-vent">
                        <br/><br/>
                        <p>Puede volver a cambiar las preferencias más tarde desde Ajustes o desde Mi Perfil.</p>
                    </div>
                    <div className="boton" onClick={() => navigate(-1/* , { replace: true } */)}>
                        Aceptar 
                    </div>
                </div>


            </div>
        </div>
    );
}

export default ListaAjustes;
