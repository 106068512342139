import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import FichaPrepare from '../components/vistas/FichaPrepare.js';
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFCargo() {
  
  /* const { idCargo } = useParams(); // Recoge el parámetro `id` de la URL */
  const { idComparsa } = useParams(); // Recoge el parámetro `id` de la URL
  const { anyo } = useParams(); // Recoge el parámetro `id` de la URL

  return (
    <div>
      <Minibanner />
      <Relleno />
      <FichaPrepare type="cargo" id={idComparsa} anyo={anyo} />
      {/* <FichaPrepare type="cargo" id={idCargo} /> */}
    </div>
  );
}

export default PageFCargo;