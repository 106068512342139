import React from 'react';

function CompMenuMiniBanner({ id }) {

  return (
    <div className="mbrell">
      <div className="mbrell-principal">
        <img src={`/comercios/mb.png`} alt="BannerMini" />
      </div>
    </div>
  );
}

export default CompMenuMiniBanner;
