import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CompMenuBanner() {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        console.log('Realizando la consulta a la API para obtener banners'); // Este log te indicará cuántas veces se hace la consulta
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner`);
        if (!response.ok) {
          throw new Error('Error al obtener los banners');
        }
        const banners = await response.json();

        // Obtener el índice del último banner mostrado desde localStorage
        let currentIndex = parseInt(localStorage.getItem('bannerIndex'), 10) || 0;

        // Incrementar el índice para mostrar el siguiente banner
        currentIndex = (currentIndex + 1) % banners.length;

        // Guardar el nuevo índice en localStorage
        localStorage.setItem('bannerIndex', currentIndex);

        // Establecer el banner actual
        let currentBanner = banners[currentIndex];

        // Verificar si el enlace ya existe y es válido
        const enlaceExiste = currentBanner.enlace_banner && currentBanner.enlace_banner.trim() !== "";

        // Si el enlace está vacío o nulo, asignar una ruta alternativa
        if (!enlaceExiste) {
          currentBanner.enlace_banner = `/comercio/${currentBanner._id}`; // Ruta alternativa
        }
        console.log(currentBanner._id); // Este log te indicará cuántas veces se hace la consulta
        setBanner(currentBanner);
      } catch (error) {
        console.error('Error al obtener el banner:', error);
      }
    };

    fetchBanners();
  }, []); // El array vacío hace que useEffect se ejecute solo una vez al montar el componente

  if (!banner) {
    return null; // No renderiza nada si no hay banner
  }

  return (
    <Link
      to={banner.enlace_banner}
      style={{ textDecoration: 'none' }}
      className="banner"
      target={banner.enlace_banner.startsWith('http') ? "_blank" : "_self"} // Si el enlace es externo, se abre en una pestaña nueva
      rel={banner.enlace_banner.startsWith('http') ? "noopener noreferrer" : undefined} // Para seguridad al abrir en una nueva pestaña
    >
      <div>
        <img src={`/banners/menu_${banner._id}_b.jpg`} alt="Banner" />
      </div>
    </Link>
  );
}

export default CompMenuBanner;
