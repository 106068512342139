import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Relleno from '../components/menu/CompMenuMBT';
import ListaAcercaDe from '../components/vistas/ListaAcercaDe.js';

function AboutPage() {
  return (
    <div>
      <Minibanner />
      <Relleno />
      <div>
        <ListaAcercaDe />
      </div>
    </div>
  );
}

export default AboutPage;
