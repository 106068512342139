// UserProfileModal.js
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; // Importa Link para los enlaces
import Grid from '../GridPrepare'; // Asegúrate de ajustar la ruta si es necesario

function CompUserProfileModal({ onClose }) {

  const [comparsa, setComparsa] = useState(localStorage.getItem('selectedComparsa') || '');
  const [filaId, setFilaId] = useState(localStorage.getItem('selectedFilaId') || '');
  let [filaNombre, setFilaNombre] = useState(localStorage.getItem('selectedFilaNombre') || '');

  if (filaId < 1) {
    filaNombre = '';
  }

  if (!comparsa) {
    localStorage.setItem('selectedComparsa', 0);
  }

  const modalRef = useRef(null); // Referencia al modal

  // Función para cerrar el modal con animación de deslizamiento hacia abajo
  const handleClose = () => {
    const modalElement = modalRef.current;
    modalElement.style.animation = 'slideDown 0.5s ease-out forwards';
    setTimeout(onClose, 500); // Espera a que termine la animación antes de cerrarlo
  };

/*   // Evitar que el modal se cierre cuando se hace clic dentro de él
  const handleInsideClick = (e) => {
    e.stopPropagation(); // Detener la propagación para que no cierre el modal
  }; */


  useEffect(() => {
    const modalElement = modalRef.current;
    modalElement.style.animation = 'slideUp 0.5s ease-out forwards';
  }, []);
  
console.log('selectedFilaId: ',filaId);
  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content"
        ref={modalRef} /* onClick={handleInsideClick} */>

        <div className="modal-content-int">
          <button className="modal-close" onClick={handleClose}>
            &times;
          </button>

          <div className="grid-title">
              <b>Mi Perfil</b>
          </div>

          {/* Comparsa y Fila */}
          <div className="perfil-section">
          {comparsa === '0' ? (
              <>
                {/* Mostrar Actos Generales si la comparsa es '0' */}
                <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
                  <strong>Mi Comparsa</strong> Actos Generales
                  <div className="perfil-image-container">
                   <img src={`/comparsas/c_${comparsa}.png`} alt="Mi Comparsa" className="perfil-image" />
                  </div>
                  <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
                </Link>
                <div className="perfil-item">
                  <strong>Mi Fila</strong> Sin Comparsa seleccionada
                </div>
              </>
            ) : (
              <>
                {/* Mostrar información normal si hay una comparsa seleccionada */}
                <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
                  <span>Mi Comparsa</span>
                  <div className="perfil-image-container">
                    <img src={`/comparsas/c_${comparsa}.png`} alt="Mi Comparsa" className="perfil-image" />
                  </div>
                  <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
                </Link>
                <Link to="/ajustes" className="perfil-item" onClick={handleClose}>
                  <span>Mi Fila</span>
                  <div className="nombre-fila"><b> {filaNombre || 'No seleccionada'} </b></div>
                  <span className="right-arrow"></span> {/* Flecha antes de la imagen */}
                </Link>
              </>
            )}

          </div>

          {/* Actos Obligatorios */}
          {filaNombre && (
            <div className="perfil-item">
              <div className="grup-star">
                <img src={`/actos/star.png`} alt="star" className="star" />
                <div><strong>Asignado a mi Fila:</strong></div>
              </div>
              <Grid type="actosFila" id={filaId} id2={comparsa} />
            </div>
          )}
        </div>


      </div>
    </div>
  );
}

export default CompUserProfileModal;
