import React from 'react';
import MapComponent from '../MapComponent';

const ListaMap = ({punto, recorrido}) => {
    // const punto = '38.483343, -0.776161, Cargos 2022 ; 38.483205, -0.773662, Cargos 2023; 38.484785, -0.773461, Cargos 2024; 38.484021, -0.771474, Cargos 2025; 38.484135, -0.771223, Cargos 2026; 38.484630, -0.770941, Cargos 2027; 38.485078, -0.773367, Cargos 2028;38.485581, -0.773235, Cargos 2029';
    // const recorrido = '38.483343, -0.776161; 38.483205, -0.773662; 38.484785, -0.773461; 38.484021, -0.771474; 38.484135, -0.771223; 38.484630, -0.770941; 38.485078, -0.773367; 38.485581, -0.773235';
  
    // Función para generar el enlace de Google Maps para un marcador
    function generateGoogleMapsLinks(punto) {
        if (!punto || punto.trim() === '') {
            return null; // Si no hay puntos, no generar enlaces
        }     
        // Dividir los diferentes puntos
        const puntos = punto.split(';').map(p => p.trim());      
        // Crear un array de enlaces en formato JSX
        return puntos.map((p, index) => {
            // Separar latitud, longitud y título
            const [lat, lng, title] = p.split(',').map(p => p.trim());    
            // Validar que lat y lng no estén vacíos
            if (!lat || !lng) return null;   
            // Crear el enlace con el título
            return (
                <a
                key={`google-map-link-${index}`} 
                href={`https://www.google.com/maps?q=${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
                className="grid-item-large-small"
                style={{margin:'15px'}}
                >
                    <div className="text-container">
                        <b>{`Ver ${title || 'Ubicación'}`}</b>
                    </div>
                </a>
            );
        });
    }

    // Función para generar un enlace de Google Maps con una ruta
    const generateGoogleMapsRouteLink = () => {
        if (!recorrido || recorrido.trim() === '') {
            return null; // Si no hay recorrido, no generar enlace
        }
        
        // Crear el enlace de ruta con la función de transformación de puntos en ruta
        return (
            <a
            key={"google-map-route-link"} 
            href={generateGoogleMapsRouteUrl(recorrido)}
            target="_blank"
            rel="noopener noreferrer"
            className="grid-item-large-small"
            style={{margin:'15px'}}
            >
                <div className="text-container">
                    <b>Ver recorrido</b>
                </div>
            </a>
        );
    }
    
    // Función auxiliar para generar el enlace de ruta de Google Maps
    function generateGoogleMapsRouteUrl(recorrido) {
        const coordinates = recorrido.split(';').map(coord => coord.trim());
        const origin = coordinates[0];
        const destination = coordinates[coordinates.length - 1];
        const waypoints = coordinates.slice(1, coordinates.length - 1).join('|');
    
        return `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}&travelmode=walking`;
    };


    return (
        <div style={{margin:'15px'}}>
            <MapComponent punto={punto} recorrido={recorrido} />
{/*             <div>
                <p>punto:</p>
                <p>{punto}</p>
                <p>recorrido:</p>
                <p>{recorrido}</p>
            </div> */}
            <div>
                  {/* Enlaces a Google Maps */}
                <div className="grid-title" style={{margin:'25px 0px 0px 0px'}}>
                    <b>Desde Google Maps:</b>
                </div>
                {generateGoogleMapsLinks(punto)}
                {generateGoogleMapsRouteLink(recorrido)}
            </div>
            <br></br><br></br><br></br><br></br><br></br><br></br>
        </div>
    );
}

export default ListaMap;
