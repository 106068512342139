import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importamos Link de react-router-dom

function GridM00b({ items, ruta, src, titulo }) {

  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    if (items.length > 0) {
      setCargando(false); // Deja de mostrar "Cargando..." cuando los items están disponibles
    }
  }, [items]);

  return (
    <div className="grid-container-a">
      <div className="grid-title"><b>{titulo}</b></div>
      <div className="inner-scroll-container">
        {cargando ? (
          <div className="title-vent">
            <b>Cargando...</b>
          </div>
        ) : (
          items.map((item, index) => (
            <Link 
              /*  Si empieza po "O" serán órdenes, si no cargos (y pasamos nombre (año)) */
              to={
                titulo?.startsWith("O")
                  ? `${ruta}${item.id}`
                  : `/comparsa/${item.idPadre}/cargos/${item.nombre}`
              }
              key={index} 
              className="grid-item-t"
            >
              <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image-b" />
              <b className="grid-text-b">{item.nombre}</b>
            </Link>
          ))
        )}
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  );
}

export default GridM00b;



