import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import FichaPrepare from '../components/vistas/FichaPrepare.js';
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFLugar() {
  
  const { idLugar } = useParams(); // Recoge el parámetro `id` de la URL

  return (
    <div>
      <Minibanner />
      <Relleno />
      <FichaPrepare type="lugar" id={idLugar} />
    </div>
  );
}

export default PageFLugar;
