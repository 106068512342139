import React from 'react';

function GridAVISOS({ avisos, id }) {
  return (
    <div className="home2">
      <div className="home-vent vent">
        <div className="title-vent">
        <b>AVISOS IMPORTANTES</b><br />
          </div>
          <br />
        {avisos.map((aviso, index) => {
          return (
            <div className="aviso-actos" key={index}>
              <p>Aviso para {aviso.idPadre !== 0 ? `${aviso.nombrePadre}` : "TODAS las Comparsas"}</p>
              <p><b>{aviso.nombre}</b></p>
              <p>{aviso.descripcion}</p>
            </div>
          );
        })}


      </div>
    </div>
  );
}

export default GridAVISOS;



