import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import ListaOrdenes from '../components/vistas/ListaOrdenes.js';

function PageListaOrdenes() {
  return (
    <div>  
      <Minibanner />
      <ListaOrdenes />
    </div>
  );
}

export default PageListaOrdenes;
