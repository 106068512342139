import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom

function Grid10M({ items, rImgComparsa, rImgCargos, rImgFila, rComparsa, rCargos, rFila, titulo, id }) {
  const navigate = useNavigate();
  let comparsaMostrada = false;

  return (
    <div className="home2">
      <div className="grid-container">
        {items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!comparsaMostrada && (
                <React.Fragment key={index}>
                  <div
                    onClick={() => navigate(`${rComparsa}`/* , { replace: true } */)}
                    className="grid-item grid-item-large"
                  >
                    <img src={`${rImgComparsa}`} alt={item.nombrePadre} className="grid-image" />
                    <b>{item.nombrePadre}</b>
                  </div>

                  <div
                    onClick={() => navigate(`/comparsa/${id}/cargos`/* , { replace: true } */)}
                    className="grid-item-large-small"
                  >
                    <img src={'/actos/act_107ico.png'} alt="act_107ico" className="grid-image" />
                    <div className="text-container">
                      <b>Cargos Festeros</b>
                    </div>
                    <img src={`${rImgCargos}`} alt="act_39ico" className="grid-image" />
                  </div>

                  <div className="grid-title"><b>{titulo}</b></div>
                </React.Fragment>
              )}
                
              <div
                onClick={() => navigate(`${rFila}${item.id}`/* , { replace: true } */)}
                className="grid-item"
              >
                <img src={`${rImgFila}${item.imagen}`} alt={item.nombre} className="grid-image" />
                <p>{item.nombre}</p>
              </div>
              {comparsaMostrada = true}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Grid10M;
