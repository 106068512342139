import React, { useEffect } from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Relleno from '../components/menu/CompMenuMBT';
import Grid from '../components/GridPrepare';

import { useParams } from 'react-router-dom';

function PageListaAvisos() {

  const { idComparsa } = useParams(); // Recoge el parámetro `id` de la URL
  // Usar useEffect para desplazar al principio cuando se monta el componente
  useEffect(() => {
    window.scrollTo(0, 0); // Colocar el scroll en el inicio de la página
  }, []);

  return (
    <div>
      <Minibanner />
      <Relleno />
        <Grid type="avisos" id={idComparsa} />
    </div>
  );
}

export default PageListaAvisos;

