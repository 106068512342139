const moment = require('moment-timezone');
class AjusteFecha {
    static fechaMilisToStringAprox(minutos, fechaActo) {
        const ahora = moment.tz('Europe/Madrid'); // Fecha y hora actual
        const fechaActoMoment = moment(fechaActo, moment.ISO_8601); 


        // 86400 seg x dia
        // 3600 seg x hora
        if (minutos < 0) {
            return "Finalizado";
        }

  
        // más de dos años
        if (minutos > 1051200) {
            return "Quedan más de 2 años";

        }// más de un año
        else if (minutos > 525600) {
            return "Queda más de un año";

        }// quedan horas 

        
        // Determinar el día del acto, ajustando si el evento es antes de las 6:00 AM
        let diaDelActo = fechaActoMoment.dayOfYear();
        if (fechaActoMoment.hour() < 6) {
            diaDelActo -= 1; // Si es antes de las 6:00 AM, considerarlo como parte del día anterior
        }

        // Determinar el día de hoy, ajustando si la hora actual es antes de las 6:00 AM
        let diaDeHoy = ahora.dayOfYear();
        if (ahora.hour() < 6) {
            diaDeHoy -= 1; // Si es antes de las 6:00 AM, considerarlo como parte del día anterior
        }
        
        
        const diferenciaDias = diaDelActo - diaDeHoy;
// console.log('_______________________________________');
// console.log('Ahora :', ahora);
// console.log('fechaActoMoment :',fechaActoMoment);
// console.log('minutos ', minutos);
// console.log('diaDelActo ', diaDelActo);
// console.log('diaDeHoy ', diaDeHoy);
// console.log('_______________________________________');


            
        // Mostrar eventos de hoy y mañana.
        if (diferenciaDias === 0) {
            // El evento es hoy.
            if (minutos < 1440) { // Si quedan menos de 24 horas.
                const horas = Math.floor(minutos / 60);
                const minutosRestantes = minutos % 60;
                if (horas > 0) {
                    return horas > 1 ? `En unas ${horas} horas` : "Dentro de 1 hora";
                }
                return minutosRestantes > 1 ? `En unos ${minutosRestantes} minutos` : "Empieza ya";
            }
            return "El evento es hoy";
        } else if (diferenciaDias === 1) {
            // El evento es mañana.
            const horasRestantes = Math.floor(minutos / 60);
            const eventoDesdeManana = fechaActoMoment.hour() >= 17; // El evento empieza desde las 5 PM de mañana
        
            // Si quedan más de 24 horas o el evento comienza desde las 5 PM de mañana, decimos "El evento es mañana".
            if (horasRestantes > 22 || eventoDesdeManana) {
                return "Mañana";
            }
        
            // Si quedan menos de 24 horas y el evento comienza antes de las 5 AM, mostramos las horas restantes.
            return `En unas ${horasRestantes} horas`;
        }

        // Si el evento es más allá de mañana pero dentro de un año.
        let dias;
        if (fechaActoMoment.year() === ahora.year()) {
            // Si es el mismo año, usamos la diferencia de días calculada.
            dias = diferenciaDias;
        } else {
            // Si es en un año diferente, calculamos los días dividiendo los minutos.
            dias = Math.floor(minutos / 1440); // 1440 minutos en un día.
        }

        // Ajuste para eventos tempranos (antes de las 6 AM).
        if (fechaActoMoment.hour() < 6) {
            dias--;
        }

        // Ajuste para la hora actual si es antes de las 6 AM.
        if (ahora.hour() < 6) {
            dias++;
        }

        return dias > 1 ? `Dentro de ${dias} días` : "Dentro de 1 día";
    }

    
    static getDia(i) {
        const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
        return dias[i] || "";
    }

    static getMes(i) {
        const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
        return meses[i] || "";
    }

    static getMesCompleto(i) {
        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        return meses[i] || "";
    }


    static fechaToString(n, horaDesconocida = false) {
        // Si la hora es desconocida, mostramos '??:??' o '--:--' en lugar de la hora real
        const horaStr = horaDesconocida ? '' : `- ${n.hours().toString().padStart(2, '0')}:${n.minutes().toString().padStart(2, '0')}`;
        return `${AjusteFecha.getDia(n.day())} ${n.date()} ${AjusteFecha.getMes(n.month())} ${horaStr}`;
    }

    static fechaToStringCorto(n) {
        return `${n.date().toString().padStart(2, '0')}/${(n.month() + 1).toString().padStart(2, '0')}/${n.year()} - ${n.hours().toString().padStart(2, '0')}:${n.minutes().toString().padStart(2, '0')} - ${AjusteFecha.getDia(n.day())}`;
    }

    static horaToString(n) {
        return `${n.hours()}:${n.minutes().toString().padStart(2, '0')}`;
    }
}

module.exports = AjusteFecha;