import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Grid from '../components/GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import Relleno from '../components/menu/CompMenuMBT';


import { useParams } from 'react-router-dom';

function PageFormaListaFilasCuadros() {

  const { idComparsa } = useParams(); // Recoge el parámetro `id` de la URL
  localStorage.setItem('selectedIdTemporal', idComparsa);

  return (
    <div>
      <Minibanner />
      <Relleno />
      <Grid type="filas" id={idComparsa} />
    </div>
  );
}

export default PageFormaListaFilasCuadros;
