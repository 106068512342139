import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import ListaAjustes from '../components/vistas/ListaAjustes.js';
import Relleno from '../components/menu/CompMenuMBT';



function PageEstAjustes() {
  return (
    <div>
      <Minibanner />
      <Relleno />
      <div>
        <ListaAjustes />
      </div>
    </div>
  );
}

export default PageEstAjustes;
