import React, { useState, useEffect } from 'react';

// Función para obtener el valor de localStorage o usar un valor predeterminado
const getInitialValue = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
};

export const CookiesContext = React.createContext();

export const CookiesProvider = ({ children }) => {
  // Inicializar los valores con localStorage si existen, o en false si no
  const [ubicacion, setUbicacion] = useState(() => getInitialValue('ubicacion', false));
  const [politicas, setPoliticas] = useState(() => getInitialValue('politicas', false));
  const [almacenamiento, setAlmacenamiento] = useState(() => getInitialValue('almacenamiento', false));
  const [personalizacion, setPersonalizacion] = useState(() => getInitialValue('personalizacion', false));
  const [cookiesAceptadas, setCookiesAceptadas] = useState(() => getInitialValue('cookiesAceptadas', false));

  // Efecto para guardar los valores en localStorage cuando cambien
  useEffect(() => {
    localStorage.setItem('ubicacion', JSON.stringify(ubicacion));
    localStorage.setItem('politicas', JSON.stringify(politicas));
    localStorage.setItem('almacenamiento', JSON.stringify(almacenamiento));
    localStorage.setItem('personalizacion', JSON.stringify(personalizacion));
    
    // Si las tres preferencias están en true, aceptamos las cookies
    const accepted = almacenamiento && personalizacion && ubicacion && politicas;
    setCookiesAceptadas(accepted);
    localStorage.setItem('cookiesAceptadas', JSON.stringify(accepted));
  }, [ubicacion, almacenamiento, personalizacion, politicas]);

  return (
    <CookiesContext.Provider value={{
        politicas,
        setPoliticas,
        ubicacion,
        setUbicacion,
        almacenamiento,
        setAlmacenamiento,
        personalizacion,
        setPersonalizacion,
        cookiesAceptadas,
        setCookiesAceptadas,
    }}>
      {children}
    </CookiesContext.Provider>
  );
};
