import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom
import { useMemo } from 'react';

function GridActosFila({ items, ruta, src }) {
  const itemRefs = useRef([]);
  const navigate = useNavigate(); // Hook para programar la navegación
  const [cargando, setCargando] = useState(true);

  const actos = useMemo(() => items.actos || [], [items]); // Asegurarte de acceder al array de 'actos'

  useEffect(() => {
    if (actos.length > 0) {
      setCargando(false); // Datos listos, no está cargando
    }
  }, [actos]);

  console.log('Datos recibidos en items gridactosfila:', items);
  console.log('tamaño en items gridactosfila:', actos.length);
    return (
      <div className="inner-scroll-container-obli">
            {actos.length > 0 ? (
                <div>
                  {cargando ? (
                    <div className="title-vent">
                      <b>Cargando actos...</b>
                    </div>
                  ) : (
                    actos.map((item, index) => {
/*                     const AjusteFecha = require('../utils/AjusteFecha'); */

                    // Verificar cada item antes de renderizarlo
                    if ((!item || !item.id || !item.imagen || !item.nombre) && (item.id !== -1)) {
                      console.error('Error en item:', item);
                      return <div key={index}>Error en la data de este item.</div>;
                    }

                    // Separadores
                   if (item.id === -1) {
                      return (
                        <div 
                          key={index} 
                          className="grid-separator-a" 
                          style={{ marginTop: '5px' }}
                          ref={(el) => (itemRefs.current[index] = el)} // Asignar ref a los separadores
                        >
                          <b className="grid-text-a">{item.nombre}</b>
                        </div>
                      );
                    } else {
                      // Actos
                      let backgroundColor = 'rgba(249, 249, 249, 0.5)';
                      let tituloColor = 'rgb(12, 110, 12)'; // Verde por defecto
                      let texto1Color = 'rgb(12, 110, 12)'; // Verde por defecto
/*                       let texto2Color = 'rgb(43, 54, 42)'; // Negro por defecto
                      let texto2Contenido = AjusteFecha.fechaMilisToStringAprox(item.dif);
                      let texto2Estilo = 'normal';
                      let mostrarBarra = true; */

                      // Acto ya ha pasado
                      if (item.dif < 0) {
                        // Acto terminado
                        if ((item.dif + item.duracion) < 0) {
                          backgroundColor = 'rgba(125, 125, 125, 0.5)'; // negro_transparente2
                          tituloColor = 'rgb(128, 128, 128)'; // gris
                          texto1Color = 'rgb(128, 128, 128)'; // gris
/*                           texto2Color = 'rgb(128, 128, 128)'; // gris
                          texto2Contenido = 'Finalizado';
                          mostrarBarra = false; // Ocultar la barra de progreso */
                        } else {
                          // Acto en proceso
                          tituloColor = 'rgb(43, 54, 42)'; // Negro
                          texto1Color = 'rgb(43, 54, 42)'; // Negro
/*                           texto2Color = 'rgb(12, 110, 12)'; // Verde
                          texto2Contenido = 'INICIADO';
                          texto2Estilo = 'bold'; // Negrita */
                        }
                      }
/*                    else {
                        // Acto no ha comenzado
                        if (item.dif < 180) { // Menos de 3 horas
                          texto2Color = 'rgb(255, 150, 0)'; // Naranja
                          texto2Estilo = 'bold'; // Negrita
                        }
                        // En otros casos, mantenemos el estilo por defecto
                      } */



                      return (
                        // ACTO
                        <div 
                        key={index}
                        className="grid-item-a"
                        ref={(el) => (itemRefs.current[index] = el)}
                        style={{ backgroundColor: backgroundColor }}
                        onClick={() => {
                          localStorage.setItem('posicionActoTemp', index); // Guardar el índice en localStorage
                          navigate(`${ruta}${item.id}`); // Navegar a la página del acto
                        }}
                        >
                          <div className="grid-content-a">
                            <div className="grid-column-text-a-obli">
                              <div className="grid-row-top-a">
                                <p className="grid-item-start-a-obli" style={{ color: texto1Color }}>{item.inicio}</p>
                                <p className="grid-item-title-a-obli" style={{ color: tituloColor }}>{item.nombre}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }))}
              </div>
            ) : (
              <p style={{ color: 'red' }}>Sin actos asociados disponibles.<br/><br/><span style={{ color: 'green', fontSize: '10px'}}>Todos los actos de Fiestas son obligatorios,<br/>pero muchas Comparsas asignan <strong>acompañamientos y recogidas</strong> de Cargos Festeros a sus Filas.</span></p>
            )}
      </div>
    );
}

export default GridActosFila;