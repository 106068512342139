import React from 'react';
import { Link } from 'react-router-dom'; // Importamos Link de react-router-dom

function GridM00({ items, ruta, src, titulo }) {
  return (
    <div className="home2">
      <div className="grid-container-t">
        <div className="grid-title"><b>{titulo}</b></div>
        {items.map((item, index) => {
          return (
          <Link to={`${ruta}${item.id}`} key={index} className="grid-item-t">
            <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image-t" />
            <b className="grid-text-t">{item.nombre}</b>
          </Link>
          );
        })}
      </div>
    </div>
  );
}

export default GridM00;



