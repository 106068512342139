import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Grid from '../components/GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import Relleno from '../components/menu/CompMenuMBT';


function PageListaTiposComercio() {
  return (
    <div>
      <Minibanner />
      <Relleno />
      <Grid type="comercio-tipos" />
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default PageListaTiposComercio;