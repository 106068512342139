import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto
export const ComparsaContext = createContext();

// Proveedor del contexto
export const ComparsaProvider = ({ children }) => {
  const [selectedComparsaId, setSelectedComparsaId] = useState(() => {
    return localStorage.getItem('selectedComparsa') || '0';
  });

  // Sincroniza con localStorage cuando cambie selectedComparsaId
  useEffect(() => {
    localStorage.setItem('selectedComparsa', selectedComparsaId);
    localStorage.setItem('selectedIdTemporal', selectedComparsaId);
  }, [selectedComparsaId]);

  return (
    <ComparsaContext.Provider value={{ selectedComparsaId, setSelectedComparsaId }}>
      {children}
    </ComparsaContext.Provider>
  );
};
