import React, { useEffect, useState, useMemo } from 'react';
import Ficha from './Ficha';
import FichaCargo from './FichaCargo';

function FichaPrepare({ type, id, anyo }) {
// function FichaPrepare({ type, id }) {
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = useMemo(() => {
    switch (type) {
      case 'acto':
        return `${process.env.REACT_APP_API_URL}/acto/${id}`;
      case 'orden':
        return `${process.env.REACT_APP_API_URL}/orden/${id}`;
      case 'comparsa':
        return `${process.env.REACT_APP_API_URL}/comparsa/${id}`;
      case 'cargo':
        // return `${process.env.REACT_APP_API_URL}/cargo/${id}`;
        return `${process.env.REACT_APP_API_URL}/cargo/${id}/${anyo}`;
      case 'fila':
        return `${process.env.REACT_APP_API_URL}/fila/${id}`;
      case 'lugar':
        return`${process.env.REACT_APP_API_URL}/lugar/${id}`;
      case 'comercio':
        return `${process.env.REACT_APP_API_URL}/comercio/${id}`;
      default:
        console.error('Tipo no válido');
        return null;
    }
  }, [type, id, anyo]);

  useEffect(() => {
    const fetchData = async () => {
      if (!url) {
        console.error('No URL provided');
        return;
      }

      console.log('Fetching data from:', url); // Debugging line

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        console.log('Datos recibidos:', data); // Debugging line
        setItems(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err); // Debugging line
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!items) {
    return <div>No se encontraron datos</div>;
  }

  let rPadre;
  let rHijo;

  switch (type) {
    case 'acto':
      rPadre = "/comparsas/";
      rHijo = "/actos/"; 
      break;

    case 'orden':
      rPadre = "/comparsas/";
      rHijo = "/actos/";
      break;

    case 'comparsa':
      rPadre = "/comparsas/";
      rHijo = "/comparsas/";
      break;

    case 'cargo':
      rPadre = "/comparsas/";
      rHijo = "/cargos/";
      break;

    case 'fila':
      rPadre = `/comparsas/`;
      rHijo = "/filas/";
      break;
      
    case 'lugar':
      rPadre = "/lugares/";
      rHijo = "/lugares/";
      break;

    case 'comercio':
      rPadre = "/comercios/";
      rHijo = "/comercios/";
      break;

    case 'festero':
      
      break;

    default:
      console.error('Tipo no válido');
      return;
  }
  
  if (type === "cargo") {
    return <FichaCargo items={items} type={type} rPadre={rPadre} rHijo={rHijo}/>;
  }
  
  return <Ficha items={items} type={type} rPadre={rPadre} rHijo={rHijo}/>;
}

export default FichaPrepare;
