import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom


function Grid1M00({ items, ruta, src, titulo, posicionActo, avisos, id }) {
  const itemRefs = useRef([]);
  const navigate = useNavigate(); // Hook para programar la navegación
  const [cargando, setCargando] = useState(true);


  //   useEffect(() => {
  //   if (items.length > 0) {
  //     setCargando(false); // Datos listos, no está cargando
  //   }

  //   if (posicionActo !== null && typeof posicionActo === 'number' && itemRefs.current[posicionActo]) {
  //     setTimeout(() => {
  //       if (itemRefs.current[posicionActo]) {
  //         localStorage.setItem('posicionActoTemp', 0);
  //         itemRefs.current[posicionActo].scrollIntoView({ behavior: 'smooth', block: 'center' });
  //       }
  //     }, 0);
  //   }
  // }, [items, posicionActo]);


  useEffect(() => {
    console.log('Ejecutando useEffect con items:', items, 'y posicionActo:', posicionActo);
    
    if (items.length > 0) {
      setCargando(false);
      console.log('Items cargados y setCargando(false) llamado');
    }
  
    if (posicionActo !== null && typeof posicionActo === 'number') {
      console.log('Verificando si la referencia al elemento está asignada:', itemRefs.current[posicionActo]);
  
      const elemento = itemRefs.current[posicionActo];
      if (elemento) {
        setTimeout(() => {
          console.log('Haciendo scroll a:', elemento);
          elemento.scrollIntoView({ behavior: 'smooth', block: 'center' });
          localStorage.setItem('posicionActoTemp', 0);
        }, 100); // Aumenta el tiempo de espera si es necesario
      } else {
        console.log('Referencia aún no asignada, esperando...');
        setTimeout(() => {
          const elementoReintento = itemRefs.current[posicionActo];
          if (elementoReintento) {
            elementoReintento.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            console.log('El elemento aún no está disponible después del reintento');
          }
        }, 500); // Reintento con más tiempo de espera
      }
    } else {
      console.log('PosicionActo es null o no es un número');
    }
  }, [items, posicionActo]);
  

  const [avisoInicial, setAvisoInicial] = useState(0);

  useEffect(() => {
    // Obtén el estado de avisoInicial de localStorage cuando el componente se monta
    const avisoGuardado = localStorage.getItem('avisoInicial');
    if (avisoGuardado !== null) {
      setAvisoInicial(parseInt(avisoGuardado, 10));
    }
  }, []);


  const handleAvisoClick = () => {
    setAvisoInicial(1);
    localStorage.setItem('avisoInicial', 1);

    navigate(`/avisos/${id}`); // Cambia '/ruta-deseada' por la ruta a la que quieres que navegue
  };


  return (
    <div className="grid-container-a">
      <div className="grid-title"><b>{titulo}</b></div>
      {/* AVISOS */}
      {avisos.length > 0 && (
        <div
          className="aviso-actos"
          onClick={handleAvisoClick}
          style={{ cursor: 'pointer' }}
        >
          <b>
            {avisoInicial === 0 ? (
              // Mostrar el aviso básico solo la primera vez
              <>AVISO IMPORTANTE</>
            ) : (
              // Mostrar el aviso completo después de la primera vez
              <>
                HAY AVISOS IMPORTANTES
                {avisos.every(aviso => aviso.idPadre !== 0) &&
                  ` para ${avisos[0].nombrePadre}`}
              </>
            )}
          </b>
          <br />
          Pulsa para ver detalles
        </div>
      )}

        <div className="inner-scroll-container">
          {cargando ? (
            <div className="title-vent">
              <b>Cargando actos...</b>
            </div>
          ) : (
            items.map((item, index) => {
            const AjusteFecha = require('../utils/AjusteFecha');
            const porcentajeTranscurrido = Math.max(0, Math.min(97, ((-item.dif) / item.duracion) * 97));

            // Verificar cada item antes de renderizarlo
            if ((!item || !item.id || !item.imagen || !item.nombre) && (item.id !== -1)) {
              console.error('Error en item:', item);
              return <div key={index}>Error en la data de este item.</div>;
            }

            // Separadores
            if (item.id === -1 && index !== 0) {
              return (
                <React.Fragment key={index}>
                  <div className="grid-separator-bottom-a"></div>
                  <div 
                    className="grid-separator-a" 
                    ref={(el) => (itemRefs.current[index] = el)} // Asignar ref a los separadores
                  >
                    <b className="grid-text-a">{item.nombre}</b>
                  </div>
                </React.Fragment>
              );
            } else if (item.id === -1) {
              return (
                <div 
                  key={index} 
                  className="grid-separator-a" 
                  style={{ marginTop: '0px' }}
                  ref={(el) => (itemRefs.current[index] = el)} // Asignar ref a los separadores
                >
                  <b className="grid-text-a">{item.nombre}</b>
                </div>
              );
            } else {
              // Actos
              let backgroundColor = 'rgba(249, 249, 249, 0.5)';
              let tituloColor = 'rgb(12, 110, 12)'; // Verde por defecto
              let texto1Color = 'rgb(12, 110, 12)'; // Verde por defecto
              let texto2Color = 'rgb(43, 54, 42)'; // Negro por defecto
              let texto2Contenido = item.tiempoRestanteString;
              let texto2Estilo = 'normal';
              let mostrarBarra = true;

              // Acto ya ha pasado
              if (item.dif < 0) {
                // Acto terminado
                if ((item.dif + item.duracion) < 0) {
                  backgroundColor = 'rgba(125, 125, 125, 0.5)'; // negro_transparente2
                  tituloColor = 'rgb(128, 128, 128)'; // gris
                  texto1Color = 'rgb(128, 128, 128)'; // gris
                  texto2Color = 'rgb(128, 128, 128)'; // gris
                  texto2Contenido = 'Finalizado';
                  mostrarBarra = false; // Ocultar la barra de progreso
                } else {
                  // Acto en proceso
                  tituloColor = 'rgb(43, 54, 42)'; // Negro
                  texto1Color = 'rgb(43, 54, 42)'; // Negro
                  texto2Color = 'rgb(12, 110, 12)'; // Verde
                  texto2Contenido = 'INICIADO';
                  texto2Estilo = 'bold'; // Negrita
                }
              } else {
                // Acto no ha comenzado
                if (item.dif < 180) { // Menos de 3 horas
                  texto2Color = 'rgb(255, 150, 0)'; // Naranja
                  texto2Estilo = 'bold'; // Negrita
                }
                // En otros casos, mantenemos el estilo por defecto
              }


              if (Number(posicionActo) === index && item.dif < 0 && texto2Contenido !== 'INICIADO') {
                console.log(Number(posicionActo));  
                console.log(index);
                console.log(item.dif);
                console.log(Number(posicionActo) === index && item.dif < 0);
                backgroundColor = 'rgba(15, 75, 10, 0.401)';
                tituloColor = 'rgb(12, 110, 12)'; // Verde
                texto1Color = 'rgb(12, 110, 12)'; // Verde
                texto2Color = 'rgb(12, 110, 12)'; // Verde
                texto2Estilo = 'bold';
              } else if (Number(posicionActo) === index) {
                backgroundColor = 'rgba(144, 255, 134, 0.401)';
                console.log(Number(posicionActo));  
                console.log(index);
                console.log(item.dif);
                console.log(Number(posicionActo) === index && item.dif < 0);
              }

              return (
                // ACTO
                <div 
                key={index}
                className="grid-item-a"
                ref={(el) => (itemRefs.current[index] = el)}
                style={{ backgroundColor: backgroundColor }}
                onClick={() => {
                  localStorage.setItem('posicionActoTemp', index); // Guardar el índice en localStorage
                  navigate(`${ruta}${item.id}`); // Navegar a la página del acto
                }}
                >
                  <div className="grid-content-a">
                    <div className="grid-column-image-a">
                      <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image-a" />
                    </div>
                    <div className="grid-column-text-a">
                      <div className="grid-row-top-a">
                        <p className="grid-item-title-a" style={{ color: tituloColor }}>{item.nombre}</p>
                        <div>
                          <p className="grid-item-start-a" style={{ color: texto1Color }}>{item.inicio}</p>
                          {item.asignado && (
                            <img src={`/actos/star.png`} alt="star" className="star" />
                          )}
                        </div>  
                      </div>
                      {mostrarBarra && (
                        <div>
                          <div className="grid-row-dif-a">
                            <div className="grid-bottom-time-a" style={{ width: `${porcentajeTranscurrido}%` }}></div>
                            <div className="grid-bottom-time-b"></div>        
                          </div>
                        </div>
                      )}
                      <div className="grid-row-bottom-a">
                        <p className="grid-item-dif-a" style={{ color: texto2Color, fontWeight: texto2Estilo }}>{texto2Contenido}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }))}
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  );
}

export default Grid1M00;