import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import ListaCargos from '../components/vistas/ListaCargos.js'; // Asegúrate de ajustar la ruta si es necesario

import { useParams } from 'react-router-dom';

function PageListaCargos() {
  
  const { idComparsa } = useParams();
  console.log("idComparsa en pageListaCargos (prop):", idComparsa);

  return (
    <div>
      <Minibanner />
      <ListaCargos idComparsa={idComparsa} />
    </div>
  );
}

export default PageListaCargos;