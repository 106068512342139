import React from 'react';

function CompMenuMiniBanner({ id }) {

  return (
    <div className="minibanner">
      <div className="minibanner-background" style={{ backgroundImage: `url(/comercios/comer_${id}_mb.jpg)` }}></div>
        <div className="minibanner-principal">
        <img src={`/comercios/comer_${id}_mb.jpg`} alt="BannerMini" />
        </div>
    </div>
  );
}

export default CompMenuMiniBanner;
