import React from 'react';
import { parseTextToLinks } from '../utils/utils';

const iconMap = {
  tel: { icon: '/iconos/ic_tel.png', backgroundColor: '#FF5700', hrefPrefix: 'tel:' },
  wha: { icon: '/iconos/ic_wha.png', backgroundColor: '#25D366', hrefPrefix: 'https://wa.me/' },
  cor: { icon: '/iconos/ic_cor.png', backgroundColor: '#0e76a8', hrefPrefix: 'mailto:' },
  www: { icon: '/iconos/ic_www.png', backgroundColor: '#000FFF', hrefPrefix: '' },
  fac: { icon: '/iconos/ic_fac.png', backgroundColor: '#2658bb', hrefPrefix: '' },
  ins: { icon: '/iconos/ic_ins.png', backgroundColor: 'rgb(211, 37, 194)', hrefPrefix: '' },
  x: { icon: '/iconos/ic_x.png', backgroundColor: 'black', hrefPrefix: '' },
  you: { icon: '/iconos/ic_you.png', backgroundColor: '#FF0000', hrefPrefix: '' },
  tik: { icon: '/iconos/ic_tik.png', backgroundColor: 'rgb(76, 0, 76)', hrefPrefix: '' },
};

const SocialLinks = ({ text }) => {
  // Asegúrate de declarar 'links' usando 'const'
  const links = parseTextToLinks(text);

  return (
    <div className="container-redes">
      {links.map(({ type, value }, index) => {
        const linkInfo = iconMap[type];
        if (!linkInfo) return null; // Si el tipo no está en el mapa, lo ignoramos

        const href = `${linkInfo.hrefPrefix}${value.replace(/\s+/g, '')}`;
        return (
          <div className="social" style={{ backgroundColor: linkInfo.backgroundColor }} key={index}>
            <a href={href} target="_blank" rel="noopener noreferrer">
              <img src={linkInfo.icon} alt={type} />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default SocialLinks;
