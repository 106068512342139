import React, { useState, useEffect } from 'react';
import SpinnerComparsas from '../spinners/SpinnerComparsas';
import Grid from '../GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import Relleno from '../menu/CompMenuMBT';

function ListaCargos({ idComparsa }) {

    if (idComparsa === '0') {
        localStorage.setItem('selectedIdTemporal', idComparsa);
    }
    // Estado para la selección temporal
    const [selectedIdTemporal, setSelectedIdTemporal] = useState(localStorage.getItem('selectedIdTemporal') || idComparsa );

    // Log inicial cuando el componente se monta o se actualiza
    useEffect(() => {
        console.log("Componente montado o actualizado");
        console.log("idComparsa en listaCargos(prop):", idComparsa);
        console.log("selectedIdTemporal (estado inicial):", selectedIdTemporal);
    }, [idComparsa, selectedIdTemporal]);

    const handleSelectionChange = (id) => {
        console.log("SpinnerComparsas seleccionó:", id);
        // Se modifica el estado temporal LocalStorage
        localStorage.setItem('selectedIdTemporal', id);
        setSelectedIdTemporal(id);

        console.log("selectedIdTemporal actualizado:", id);
    };
    
    return (
        <div className="home-act-ord">
            <Relleno />
            <div className="contenedor-spinner">
                {/* SpinnerComparsas - Verificación de valores */}
                {console.log("SpinnerComparsas recibe selectedId:", selectedIdTemporal)}
                {<SpinnerComparsas onSelectionChange={handleSelectionChange} selectedId={selectedIdTemporal} type="cargos" />}
            </div>

            {/* Grid - Verificación de valores */}
            {console.log("Grid recibe id:", selectedIdTemporal)}
            <Grid type="cargos" id={selectedIdTemporal} />
        </div>
    );
}

export default ListaCargos;
