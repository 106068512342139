import React, { useEffect, useState, useMemo } from 'react';
import Grid10M from './Grid_1_0_M';
import Grid00M from './Grid_0_0_M';
import GridM00 from './Grid_M_0_0';
import GridM00b from './Grid_M_0_0b';
import Grid11M from './Grid_1_1_M';
import Grid1M00ACTOS from './Grid_1_M_0_0_ACTOS';
import GridActosFila from './GridActosFila';
import GridAVISOS from './GridAVISOS';



function GridPrepare({ type, id, id2 }) {

  const [items, setItems] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [posicionActo, setPosicionActo] = useState('0');
  const [posicionActoTemp, setPosicionActoTemp] = useState(localStorage.getItem('posicionActoTemp') || '0');


  const { url } = useMemo(() => {
    let generatedUrl = '';
  
    switch (type) {

      case 'avisos':
        generatedUrl = '';
        break;

      case 'actos':
        generatedUrl = `${process.env.REACT_APP_API_URL}/actos/${id}/${id2}`;
        break;

      case 'actosFila':
        generatedUrl = `${process.env.REACT_APP_API_URL}/actos/fila/${id}/${id2}`;
        break;

      case 'ordenes':
        generatedUrl = `${process.env.REACT_APP_API_URL}/ordenes/${id}`;
        break;

      case 'comparsas':
        generatedUrl = `${process.env.REACT_APP_API_URL}/comparsas`;
        break;

      case 'cargos':
        generatedUrl = `${process.env.REACT_APP_API_URL}/cargos/${id}`;
        break;

      case 'filas':
          generatedUrl = `${process.env.REACT_APP_API_URL}/filas/${id}`;
        break;

      case 'lugares':
        generatedUrl = `${process.env.REACT_APP_API_URL}/lugares`;
        break;

      case 'comercio-tipos':
        generatedUrl = `${process.env.REACT_APP_API_URL}/comercio-tipos`;
        break;

      case 'comercios':
        if (id) {
          generatedUrl = `${process.env.REACT_APP_API_URL}/comercios/${id}`;
        } else {
          generatedUrl = `${process.env.REACT_APP_API_URL}/comercios`;
        }
        break;

      case 'festeros':
        generatedUrl = `${process.env.REACT_APP_API_URL}/festeros`;
        break;

      default:
        generatedUrl = '';
        return;
    }
    return { url: generatedUrl };
  });


  useEffect(() => {

    // Realizar la consulta a la API
    if (url) {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log('Datos recibidos:', data);
          if (type === 'actos') {
            setItems(data.actos || []);
              if (Number(posicionActoTemp) < 1) {
                setPosicionActo(data.posicionActo || '0');
              } else {
                setPosicionActo(Number(posicionActoTemp));
              }
          } else {
            setItems(data);
          }
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [url, type, posicionActoTemp]); // Reejecuta el efecto si cambian url, type, o posicionActo
  



  const urlAviso = `${process.env.REACT_APP_API_URL}/avisos-actos/${id}`;
  useEffect(() => {
    
    // Realizar la consulta a la API
    if (type === 'actos' || type === 'avisos') {
      fetch(urlAviso)
      .then(response => response.json())
      .then(data => {
        console.log('Datos recibidos:', data);
        setAvisos(data);
      })
      .catch(error => console.error('Error fetching data:', error));
    }
  }, [urlAviso]); // Reejecuta el efecto si cambian url, type, o posicionActo 



  let titulo;
  let rutaProxima;    /* navigate(`/${proximo.current}/${item.id}` */
  let rutaImagen;     /* src={`/${effectiveType.current}/${item.imagen}`} */
  let Grid;

  switch (type) {
    case 'avisos':
      Grid = <GridAVISOS avisos={avisos} id={id} />
      break;

    case 'actos':
      titulo = 'Lista de Actos';
      rutaProxima = "/acto/";
      rutaImagen = "/actos/"; 
      Grid = <Grid1M00ACTOS items={items} ruta={rutaProxima} src={rutaImagen} titulo={titulo} posicionActo={posicionActo} avisos={avisos} id={id} />
      break;

    case 'actosFila':
      rutaProxima = "/acto/";
      rutaImagen = "/actos/"; 
      console.log('Datos recibidosen items:', items);
      Grid = <GridActosFila items={items} ruta={rutaProxima} src={rutaImagen} />
      break;

    case 'ordenes':
      titulo = 'Orden de Desfiles';
      rutaProxima = "/orden/";
      rutaImagen = "/actos/";
      Grid = <GridM00b items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'comparsas':
      titulo = 'Comparsas';
      rutaProxima = "/comparsa/";
      rutaImagen = "/comparsas/";
      Grid = <Grid10M items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'cargos':
      titulo = `Cargos Festeros `;
      rutaProxima = `/comparsa/${id}/cargos/`;
      rutaImagen = "/comparsas/";
      Grid = <GridM00b items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'filas':
      if (id === '5') {
        titulo = `Quadrelles `;
      } else {
        titulo = `Filas`;
      }
      const rComparsa = `/comparsa/${id}/info`;
      const rCargos = `/comparsa/${id}/cargos`;
      const rFila = `/comparsa/${id}/fila/`;
      const rImgComparsa = `/comparsas/c_${id}rect.png`;
      const rImgCargos = "/actos/act_39ico.png";
      const rImgFila = "/filas/";
      Grid = <Grid11M items={items} rImgComparsa={rImgComparsa} rImgCargos={rImgCargos} rImgFila={rImgFila} rComparsa={rComparsa} rCargos={rCargos} rFila={rFila} type={type} titulo={titulo} id={id} />
      break;
      
    case 'lugares':
      titulo = 'Lugares de Interés';
      rutaProxima = "/lugares-interes/";
      rutaImagen = "/lugares/";
      Grid = <Grid10M items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'comercio-tipos':
      titulo = 'Tipos de Comercio';
      rutaProxima = "/comercios/";
      rutaImagen = "/comercios/";
      Grid = <GridM00 items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'comercios':
      if (id === '200' || id === '299' || id === undefined || id === null) {
        titulo = `Comercios `;
      } else {
        titulo = `Comercios de `;
      }
      rutaProxima = "/comercio/";
      rutaImagen = "/comercios/";
      Grid = <Grid00M items={items} ruta={rutaProxima} src={rutaImagen} type={type} titulo={titulo} />
      break;

    case 'festeros':
      
      break;

    default:
      console.error('Tipo no válido');
      return;
  }

  return (
    Grid
  );
}

export default GridPrepare;

