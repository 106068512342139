import React, { useEffect } from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Relleno from '../components/menu/CompMenuMBT';
import ListaAvisoLegal from '../components/vistas/ListaAvisoLegal';

function PageAvisoLegal() {
  // Usar useEffect para desplazar al principio cuando se monta el componente
  useEffect(() => {
    window.scrollTo(0, 0); // Colocar el scroll en el inicio de la página
  }, []);

  return (
    <div>
      <Minibanner />
      <Relleno />
      <div className="home2">
        <div className="home-vent vent">
          <div className="title-vent">
            <b>Aviso legal,</b><br />
            <b>Política de Privacidad y</b><br />
            <b>Política de Cookies</b>
          </div>
          <br /><br />
          <ListaAvisoLegal />
        </div>
      </div>
    </div>
  );
}

export default PageAvisoLegal;

