import React from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Grid from '../components/GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import Relleno from '../components/menu/CompMenuMBT';

import { useParams } from 'react-router-dom';

function PageListaComercios() {

  const { idTipo } = useParams();

  return (
    <div>
      <Minibanner />
      <Relleno />
      <Grid type="comercios" id={idTipo} />
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default PageListaComercios;
