import React, { useEffect, useState, useContext } from 'react';
import logo from '../../assets/logo1024t.png';
import CompCookiesModal from '../menu/CompCookiesModal';
import { CookiesContext } from '../../context/CookiesContext';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom

function ListaAcercaDe() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); // Forzar el desplazamiento al inicio de la página
    }, []);



    const [showCookiesModal, setShowCookiesModal] = useState(false); // Controla la visualización del modal
    /*     const { cookiesAccepted } = useContext(CookiesContext); */
      
    // Extraer los valores del contexto
    const { setUbicacion, setAlmacenamiento, setPersonalizacion, setPoliticas } = useContext(CookiesContext);

    // Función que se ejecutará al hacer clic en el botón
    const handleOpenModal = () => {
        // En vez de abrir el modal, se actualizan almacenamiento y personalización a false
        setPoliticas(false);
        setUbicacion(false);
        setAlmacenamiento(false);
        setPersonalizacion(false);

        console.log('Almacenamiento y personalización establecidos en false');
    };

    const handleCloseModal = () => {
        // Tu lógica para cerrar el modal, si la necesitas
    };



    return (
        <div>
            <div className="home2">

                <img style={{ maxWidth: "80px", marginTop: "20px" }} src={logo} alt="Logo" />
                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Moros y Cristianos Petrer</b>
                    </div>
                    <div className="text-vent justify">
                        <p >
                            <br/>
                            Esta aplicación web progresiva (PWA) está en desarrollo, si hay algún dato incorrecto o quieres cambiarlo, contacta por email.
                        </p>
                    </div>
                    <div className="boton" onClick={() => window.location.href = `mailto:${process.env.REACT_APP_EMAIL}`}>
                        {process.env.REACT_APP_EMAIL}
                    </div>
                </div>


                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Petición de ayuda a Filas y Comparsas</b>
                    </div>
                    <div className="text-vent justify">
                        <p>
                            <br/>
                            Esta aplicación web está hecha para todos aquellos que disfrutan de las Fiestas y se ha puesto mucho esfuerzo.<br/><br/>
                            Si hay algún dato que creas que deba ser corregido, si prefieres que se cambie la foto de tu fila, compartir información sobre eventos en la Fiesta, o simplemente tienes alguna sugerencia, no dudes en escribir al email indicado y será corregido en cuanto sea posible.<br/><br/>
                            Esta aplicación web está en desarrollo y es posible que recibas actualizaciones para completar la información que se vaya modificando.
                        </p>
                    </div>
                </div>


                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Agradecimientos</b>
                    </div>
                    <div className="text-vent justify">
                        <p>
                            <br/>
                            A todos aquellos que han colaborado en esta aplicación, ya sea mandando sugerencias, datos, fotos, etc.<br/><br/>
                            A las Directivas de Comparsas y Unión de Festejos por facilitar la información necesaria.<br/><br/>
                            <b>Fotografías:</b><br/>
                                - Unión de Festejos San Bonifacio Mártir<br/>
                                - Vicente Olmos Navarro<br/>
                                - José Ignacio Mañez Azorín<br/>
                                - Pascual Rico Vizcaino<br/>
                                - Cristina Chacón Martínez<br/>
                                - Juanjo Rubio Soriano<br/>
                                - Abraham de la Rosa López<br/>
                                - Aitor Vidal<br/>
                                - Iván García Carretero<br/>
                                <br/>
                            <b>Fuentes de información:</b><br/>
                            - <a href="https://petrerenfestes.com" target="_blank">petrerenfestes.com</a><br/>
                            - <a href="https://turismopetrer.es" target="_blank">turismopetrer.es</a><br/>
                            - <a href="https://museodamasonavarro.es" target="_blank">museodamasonavarro.es</a><br/>
                            - <a href="https://es.wikipedia.org" target="_blank">es.wikipedia.org</a><br/>
                        </p>
                    </div>
                </div>


                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Comercios</b>
                    </div>
                    <div className="text-vent justify">
                        <p>
                            <br/>
                            Todos los comercios que aparecen a continuación han dado su consentimiento a través de mail y toda la información e imágenes son propiedad de los mismos, dando autorización exclusivamente para ser mostrados en esta aplicación web.<br/><br/>Si alguien quiere modificar o eliminar información deberá comunicarlo por email.
                        </p>
                    </div>
                </div>


                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Aviso legal,</b><br/>
                        <b>Política de Privacidad y</b><br/>
                        <b>Política de Cookies</b>
                    </div>
                    <div className="text-vent justify">
                        <p>
                            <br/>
                            En la aplicación Moros y Cristianos Petrer <b>NO almacenamos sus datos personales</b>.<br/><br/>
                            Cualquier preferencia que establezca se almacenará únicamente en su móvil para la gestión correcta de la aplicación.<br/><br/>
                            <b>Solo utilizamos cookies mínimas necesarias</b> para almacenar esas preferencias sin recopilar información adicional ni rastrear su actividad.
                        </p>
                    </div>
                    <div className="boton" onClick={() =>  navigate('/aviso-legal'/* , { replace: true } */)}>
                        Leer más
                    </div>
                </div>


                <div className="home-vent vent">
                    <div className="title-vent">
                        <b>Aceptación de Cookies</b>
                    </div>
                    <div className="text-vent justify">
                        <p >
                            <br/>
                            Puede modificar sus preferencias sobre cookies desde aquí.<br/><br/>
                            <b>Es importante que las acepte para poder almacenar su Comparsa y su Fila.</b>
                        </p>
                    </div>

                    {showCookiesModal && <CompCookiesModal onClose={handleCloseModal} />}
                    <button className="boton" onClick={handleOpenModal}>
                        Preferencias de Cookies
                    </button>
                </div>

            </div>
        </div>
    );
}

export default ListaAcercaDe;
